import React from "react"
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from "gatsby"

import TechnologyLayout from "../../container/TechnologyLayout"

const PatentContainer = styled.div`
  width: 100%;
`;

const PatentHead = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  border-bottom: 3px solid black;
  
  @media screen and (max-width: 768px) {
    height: 0;
    overflow: hidden;
  }
`;

const Patent = styled.div`
  display: flex;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid black;
  
  :hover {
    background-color: lightgrey;
  }
  
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    height: 70px;
  }
`;

const PatentIDCol = styled.div`
  width: 50px;
  text-align: center;
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`
const PatentTypeCol = styled.div`
  width: 70px;
  text-align: center;
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`
const PatentNameCol = styled.div`
  flex: 1 1 auto;
  
  @media screen and (max-width: 768px) {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 16px;
    line-height: 30px;
    height: 30px;
    flex: 0 0 auto;
  }
`
const PatentNumberCol = styled.div`
  width: 120px;
  text-align: center;
  
  @media screen and (max-width: 768px) {
    width: 50%;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    height: 20px;
  }
`
const PatentDateCol = styled.div`
  width: 120px;
  text-align: center;
  
  @media screen and (max-width: 768px) {
    width: 50%;
    text-align: right;
    font-size: 16px;
    line-height: 20px;
    height: 20px;
  }
`

function PatentPage () {
  const {
    allPatentListJson: {
      edges : patent
    }
  } = useStaticQuery(graphql`
      query {
          allPatentListJson {
              edges {
                  node {
                      id
                      type
                      register_number
                      name
                      register_date
                  }
              }
          }
      }
  `)

  return (
    <TechnologyLayout title="특허">
      <PatentContainer>
        <PatentHead>
          <PatentIDCol pcWidth="50" />
          <PatentTypeCol pcWidth="70" >
            종류
          </PatentTypeCol>
          <PatentNameCol>
            이름
          </PatentNameCol>
          <PatentNumberCol pcWidth="120" >
            출원 번호
          </PatentNumberCol>
          <PatentDateCol pcWidth="120" >
            등록 날짜
          </PatentDateCol>
        </PatentHead>
        {patent.map(({node}, index) => (
          <Patent>
            <PatentIDCol>{index + 1}</PatentIDCol>
            <PatentTypeCol>{node.type}</PatentTypeCol>
            <PatentNameCol>{node.name}</PatentNameCol>
            <PatentNumberCol>{node.type === "특허" ? 10 : 30}-{node.register_number}</PatentNumberCol>
            <PatentDateCol>{node.register_date}</PatentDateCol>
          </Patent>
        ))}
      </PatentContainer>
    </TechnologyLayout>
  )
}

export default PatentPage